// extracted by mini-css-extract-plugin
export var menu = "Menu-module--menu--I1RIb";
export var mobile_menu__header = "Menu-module--mobile_menu__header--rjbqi";
export var menu_item = "Menu-module--menu_item--H2G0-";
export var active = "Menu-module--active--pQ+o-";
export var mobile_menu = "Menu-module--mobile_menu--JUjwQ";
export var show = "Menu-module--show--IYm-y";
export var mobile_menu_item = "Menu-module--mobile_menu_item--UE8QI";
export var icon = "Menu-module--icon--Lvu4C";
export var mobile_menu_footer = "Menu-module--mobile_menu_footer---973N";
export var social = "Menu-module--social--FboPW";